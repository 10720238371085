import React from 'react'

const LoadingPage = () => {
  return (
    <div className="wrapper loadingPage">
    <ul className="loader-list">
      
      <li>
     
        <div className="loader-6 center"><span></span></div>
      </li>
    </ul>
</div>
  )
}

export default LoadingPage